.dashHeaderContainer {
  box-shadow: 3px 4px 6px 2px rgba(225, 225, 225, 0.75);
  display: flex;
  justify-content: center;
}

.dashHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashHeaderClosed,
.dashHeaderOpen {
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 2;
}

.dashHeaderOpen {
  width: calc(100vw - 260px);
}

.dashHeaderClosed {
  width: calc(100vw - 54px);
}

.titleContainer,
.pageSubTitleContainer {
  display: flex;
  align-items: center;
}

.pageTitleName {
  color: var(--color-true-blue);
  font-size: 23px;
  font-weight: 500;
  margin-top: 1em;
  margin-bottom: 1em;
}

.pageSectionName {
  color: #767676;
  font-size: 20px;
  margin-top: 1em;
  margin-bottom: 1em;
}

.pageSubTitleContainer {
  color: var(--color-true-blue);
  font-size: 20px;
  font-weight: 500;
  margin-top: 1em;
  margin-bottom: 1em;
}

.blackDot {
  margin-right: 8px;
  margin-left: 8px;
  height: 6px;
}

.cloudCheck {
  margin-right: 4px;
  margin-left: 16px;
  height: 20px;
}

.savedText {
  color: #767676;
  font-size: 16px;
}
