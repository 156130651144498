.frontendLayout {
  font-family: 'Lato', sans-serif;
  color: #1a1e22;
  font-weight: 400;
  font-size: 18px;
  overflow: hidden;
  background-color: #fcfcfc;
}

.frontendBody {
  position: relative;
  width: 100%;
  padding-bottom: 120px;
}

.pageNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pageNotFound.big {
  height: calc(100vh - 48px);
}

.pageNotFound.small {
  padding-top: 100px;
}

.pageNotFound .errorTitle {
  color: #1f8598;
  padding: 0 30px;
  font-size: 120px;
  font-weight: 700;
  letter-spacing: 14px;
  margin: 0 0 10px;
}

.pageNotFound .errorSubtitle {
  color: #767676;
  font-size: 30px;
  text-transform: uppercase;
  margin-top: 10px;
}

.pageNotFound .errorDescription {
  margin-bottom: 15px;
  font-weight: 300;
  letter-spacing: 1.04px;
  font-size: 16px;
  text-align: center;
}

.pageNotFound .errorBtn {
  margin-top: 20px;
}

.homepageWidth {
  width: var(--homepage-width);
}

.sectionWidth {
  width: var(--section-width);
}

.articleWidth {
  width: var(--article-width);
}

.galleryArticleWidth {
  width: var(--article-width);
}

@media (max-width: 1600px) {
  .homepageWidth {
    width: var(--homepage-width-xxl);
  }

  .sectionWidth {
    width: var(--section-width-xxl);
  }

  .articleWidth {
    width: var(--article-width-xxl);
  }

  .galleryArticleWidth {
    width: var(--article-width-xxl);
  }
}

@media (max-width: 1450px) {
  .frontendLayout {
    font-size: 16px;
  }
}

@media (max-width: 1280px) {
  .homepageWidth {
    width: var(--homepage-width-xl);
  }

  .sectionWidth {
    width: var(--section-width-xl);
  }

  .articleWidth {
    width: var(--article-width-xl);
  }

  .galleryArticleWidth {
    width: var(--article-width-xl);
  }
}

@media (max-width: 1100px) {
  .homepageWidth {
    width: var(--homepage-width-lg);
  }

  .sectionWidth {
    width: var(--section-width-lg);
  }

  .articleWidth {
    width: var(--article-width-lg);
  }

  .galleryArticleWidth {
    width: var(--article-width-lg);
  }
}

@media (max-width: 1023px) {
  .homepageWidth {
    width: var(--homepage-width-sm);
  }

  .sectionWidth {
    width: var(--section-width-xs);
  }

  .articleWidth {
    width: var(--article-width-sm);
  }

  .galleryArticleWidth {
    width: var(--article-width-sm);
  }
}