.trueHeader {
  top: 0;
  width: 100%;
  z-index: 5;
  background-color: #fcfcfc;
}

.mainHeaderInfo > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
}

.schoolHeaderTitle {
  font-size: 42px;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 4px;
}

.schoolHeaderSubtitle {
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 0;
}

.schoolHeaderSubtitle.additionalSpace {
  margin-top: 8px;
}

.mainHeaderInfo .schoolLogo {
  border-radius: 5px;
}

.mainHeaderInfo .schoolLogo.big {
  max-width: 350px;
  max-height: 130px;
}

.mainHeaderInfo .schoolLogo.small {
  max-width: 300px;
  max-height: 100px;
}

.collapsedNavbarLogo img {
  max-height: 60px;
}

.mainHeaderContent {
  position: relative;
  width: 100%;
}

.headerBkg {
  width: 100%;
  transition: 0.5s;
}

.mainHeaderContent svg {
  width: 100%;
  position: absolute;
  top: 0;
  height: auto;
  z-index: 0;
}

.headerContentSections {
  position: absolute;
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.headerContentSections > * {
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 1280px) {
  .schoolHeaderTitle {
    font-size: 38px;
  }
}

@media (max-width: 1280px) {
  .schoolHeaderTitle {
    font-size: 36px;
  }

  .schoolHeaderSubtitle {
    font-size: 14px;
  }
}

@media (max-width: 1023px) {
  .headerContentSections {
    margin-top: 0;
  }

  .headerContentSections > * {
    margin-left: 0px;
    margin-right: 0px;
  }

  .headerContentSections {
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: start;
    column-gap: 10px;
    row-gap: 5px;
  }
}

@media (max-width: 1024px) {
  .schoolHeaderTitle {
    font-size: 32px;
  }
}

@media (max-width: 768px) {
  .schoolHeaderTitle {
    font-size: 24px;
  }

  .mainHeaderInfo > div {
    padding: 12px;
  }
}

@media (max-width: 520px) {
  .schoolHeaderTitle {
    font-size: 20px;
  }
}

@media (max-width: 320px) {
  .mainHeaderInfo .schoolLogo.big,
  .mainHeaderInfo .schoolLogo.small {
    max-width: 100%;
  }
}