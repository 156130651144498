.contactWrapper {
    display: flex;
    justify-content: space-between;
}

.contactBadge {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 22px;
    background: #004552;
    color: white;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    font-size: 23px;
}

.contactLeft,
.contactRight {
    display: flex;
}

.contactInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 45px;
}

.contactInfo .contactName {
    font-size: 16px;
    font-weight: 500;
    color: #004552;
}

.contactInfo .contactEmail,
.contactRoleBadge {
    font-size: 16px;
    font-weight: 500;
    color: #767676;
}

.contactRight {
    align-items: center;
}

.contactRoleBadge {
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EFEFEF;
    border-radius: 4px;
    padding: 10px 15px;
}

.contactIcon {
    height: 20px;
    width: 20px;
    margin-right: 30px;
    cursor: pointer;
}

.contactIcon path {
    fill: #004552;
}