.textWarning {
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  padding-top: 40px;
  padding-bottom: 20px;
}

.colorContainer1 {
  display: flex;
  background-color: #f4f4f4;
  height: 100px;
  width: 100px;
  border-radius: 6px;
  box-shadow: 3px 4px 6px 2px rgba(225, 225, 225, 0.75);
  border: 4px solid transparent;
  cursor: pointer;
}

.colorContainerBorder {
  border: 4px solid #1f8598;
}

.outlinedInputStyle {
  width: 97px;
  height: 35px;
  font-weight: 500 !important;
  text-align: center !important;
  color: #156270 !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #7cb9c4 !important;
}

.paletteBottom1 {
  width: 100%;
  height: 37%;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.paletteBottom1 p {
  margin-top: 0;
  margin-bottom: 3px;
}

.paletteTitle1 {
  font-weight: 700;
  text-align: center;
}