/* Table styling */
.tableContainer {
  overflow-x: auto;
  box-shadow: 3px 4px 6px 2px rgba(225, 225, 225, 0.75);
}

.MuiTable-root {
  background: #ffffff;
  border-radius: 4px;
  outline: none;
  border: none;
}

.MuiTableHead-root {
  height: 45px !important;
  background-color: #f4f4f4;
  border-radius: 50px;
}

th.MuiTableCell-root,
td.MuiTableCell-root {
  border-bottom: none;
}

th.MuiTableCell-head {
  color: var(--color-true-blue);
  font-weight: 500;
  padding: 1px 12px;
}

table tr.MuiTableRow-head th:first-child {
  border-radius: 4px 0 0 0;
}

table tr.MuiTableRow-head th:last-child {
  border-radius: 0 4px 0 0;
}

/* tbody > tr.MuiTableRow-root:hover {
  background-color: #e5fbff;
} */

.MuiTableBody-root .MuiTableRow-root {
  border-left: 2px solid white;
}

.fill:hover {
  background-color: #e5fbff;
}

.fill:hover > *:first-child {
  border-left: 2px solid #1f8598;
}

td.MuiTableCell-root {
  min-height: 38px;
  padding: 1px 12px;
  /* background: #FFFFFF; */
}

/* Table content styling */
.attribute {
  color: #767676;
  margin-top: 1em;
  margin-bottom: 1em;
}


.attributeWidthOption {
  cursor: pointer;
}

.attributeWidthEmail {
  max-width: 330px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #767676;
}

.attributeWidth p {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tableStateWrapper {
  display: flex;
  align-items: center;
}

.tableStateWrapper img {
  margin-right: 8px;
}

.resultsWrapper {
  display: flex;
  justify-content: flex-end;
}

.results {
  margin-top: 1em;
  margin-bottom: 1em;
  color: var(--color-true-blue);
}

.noResults {
  color: #767676;
  font-weight: bold;
  line-height: 135%;
  padding: 20px;
  text-align: center;
  cursor: default;
}

th.MuiTableCell-head,
.results,
.noResults {
  font-size: 16px;
}

.attribute,
.MuiPaginationItem-root:not(.Mui-selected),
.MuiPaginationItem-root.Mui-selected {
  font-size: 15px;
}

.tableBodyContainer .noResults:hover {
  background-color: #f4f4f4;
  filter: unset;
}

/* Pagination styling */
.paginationWrapper,
.buttonsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonsWrapper > *:not(:last-child) {
  margin-left: 5px;
  margin-right: 5px;
}

.arrowContainer {
  margin-right: 5px;
  margin-left: 5px;
}

.arrowContainer img {
  height: 14px;
}

.MuiPaginationItem-root {
  border-radius: 4px !important;
}

.MuiPaginationItem-root:not(.Mui-selected) {
  color: rgba(118, 118, 118, 1) !important;
  font-weight: 400 !important;
}

.MuiPaginationItem-root:not(.Mui-selected):hover,
.MuiPaginationItem-root:not(.Mui-selected):focus {
  border-radius: 4px;
}

.MuiPaginationItem-root.Mui-selected {
  background-color: var(--color-true-blue) !important;
  color: white;
  font-weight: 400;
}

.MuiPaginationItem-root.Mui-selected:hover {
  background-color: var(--color-true-blue) !important;
  opacity: 0.8;
}

.MuiPaginationItem-root .MuiSvgIcon-root {
  fill: rgba(118, 118, 118, 1) !important;
  width: 1.5em;
  height: 1.5em;
}

.inactive-buttons {
  display: none;
}

.active-buttons {
  display: flex;
}

.optionsFlex {
  display: flex;
}

.pageLoading {
  opacity: 0.5;
  pointer-events: none;
  position: relative;
}

.tableLoadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 1;
}

@media only screen and (max-width: 1650px) {
  th.MuiTableCell-head,
  .results,
  .noResults {
    font-size: 15px;
  }

  .attribute,
  .MuiPaginationItem-root:not(.Mui-selected),
  .MuiPaginationItem-root.Mui-selected {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1280px) {
  .attributeWidthEmail {
    max-width: 300px;
  }
}
