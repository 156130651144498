.sectionsContentWrapper {
    margin-left: -10px !important;
}

.sectionsWrapper {
    margin-left: 10px;
    margin-bottom: 30px;
}

.sectionContainer {
    display: flex;
    align-items: center;
}

.sectionContainer {
    padding-left: 10px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-left: 2px solid #efefef29;
}

.sectionContainer .checkboxFlex {
    flex-grow: 1;
}

.sectionContainer:hover {
    background-color: #e5fbff;
    box-shadow: 3px 4px 6px 2px rgba(225, 225, 225, 0.75);
    border-left: 2px solid #1F8598;
}

.sectionContainer input[type=checkbox] {
    width: 26px;
    height: 20px;
    place-content: center;
}

.checkboxContainer {
    margin-top: -5px;
    margin-bottom: -5px;
}

.checkboxTxt {
    font-size: 16px;
    color: #767676;
    margin-top: -2px;
    margin-bottom: -2px;
}

.disableCursor {
    cursor: not-allowed;
}

.enableCursor {
    cursor: pointer;
}

.sectionsPagination {
    margin-top: 30px;
}

.slotItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.slotItem,
.slotItem .secondaryContainer button {
    width: 100%;
}

.slotItem svg {
    cursor: pointer;
    margin-bottom: 16px;
}

.slotItem.selected .background {
    fill: #1F8598 !important;
}

.modalBackground{
    max-height: none !important;
}

@media only screen and (max-width: 1650px) {

    .sectionText {
        font-size: 15px;
    }
}