.articleContent.first {
  border-radius: 8px 8px 0 0;
}

.articleContent.editorial {
  text-align: center;
}

.articleContent.editorial .articleNewsShare {
  justify-content: center;
}

.articleContent {
  background-color: #fcfcfc;
  padding: 24px;
  margin: 0 auto;
}

.articleContent.imgContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 0px;
}

.articleAuthor {
  margin: 0;
  font-weight: 700;
}

.articlePublishDate {
  margin-top: 4px;
  font-size: 12px;
}

.articleNewsTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  font-size: 42px;
  font-weight: 700;
  color: #1a1e22 !important;
}

.galleryArticleContainer {
  position: relative;
  padding-top: 40px;
  margin: 0 auto;
  margin-top: -2px;
  background-color: #fcfcfc;
}

.articleBody {
  font-family: 'Noto Serif', 'Georgia', serif;
  -webkit-font-smoothing: antialiased;
  margin-top: 10px;
  font-size: 18px;
  line-height: 1.5555555556;
  color: #1a1e22 !important;
}

.articleBody p,
.articleBody em,
.articleBody span,
.articleBody strong,
.articleNewsTitle p,
.articleNewsTitle em,
.articleNewsTitle span,
.articleNewsTitle strong {
  color: #1a1e22 !important;
}

.articleBody p:first-of-type {
  margin-top: 0;
}

.articleBody p {
  margin-bottom: 8px;
}

.articleBody a {
  color: #0d6efd !important;
}

.articleBody a:hover {
  color: #0a58ca !important;
}

.articleNewsShare {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.articleNewsShare span {
  font-size: 0.9rem;
}

.articleNewsShare button {
  color: inherit;
  border: 50px;
  padding: 6px 16px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 50px;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.articleImg {
  max-width: 100%;
  max-height: calc(100vh - 160px);
  border-radius: 8px;
  object-fit: cover;
}

.articleImgDesc {
  margin-top: 8px;
  font-family: 'Georgia', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  text-align: center;
  color: #64676b;
}

.articlePage .galleryContainer {
  margin-top: 24px;
}

.articlePage
  .image-gallery-content:not(.fullscreen)
  .image-gallery-slide
  .image-gallery-image,
.articlePage .image-gallery-content:not(.fullscreen) .video-wrapper {
  margin-bottom: 40px;
}

.articlePage .image-gallery-content:not(.fullscreen) .image-gallery-right-nav,
.articlePage .image-gallery-content:not(.fullscreen) .image-gallery-left-nav {
  margin-top: -20px;
}

.articlePage .image-gallery-content:not(.fullscreen) .image-gallery-fullscreen-button {
  margin-bottom: 45px;
}

.articlePage .image-gallery-slide .image-gallery-description {
  height: 45px;
  background: unset;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  white-space: normal;
  font-size: 16px;
  color: #64676b;
}

.video-wrapper-container {
  max-height: 100vh;
}

.video-wrapper {
  padding-bottom: 32px;
  position: relative;
  padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
  height: 0;
  overflow: hidden;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1450px) {
  .articleNewsTitle {
    font-size: 32px;
  }
}

@media (max-width: 1024px) {
  .articleContent {
    padding: 0;
    padding-top: 1%;
  }
}

@media (max-width: 480px) {
  .articlePage .image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image, 
  .articlePage .image-gallery-content:not(.fullscreen) .video-wrapper {
    margin-bottom: 75px;
  }

  .articlePage .image-gallery-content:not(.fullscreen) .image-gallery-right-nav,
  .articlePage .image-gallery-content:not(.fullscreen) .image-gallery-left-nav {
    margin-top: -40px;
  }

  .articlePage .image-gallery-content:not(.fullscreen) .image-gallery-fullscreen-button {
    margin-bottom: 80px;
  }

  .articlePage .image-gallery-slide .image-gallery-description {
    height: 80px;
  }
}

@media (max-width: 280px) {
  .articlePage .image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image, 
  .articlePage .image-gallery-content:not(.fullscreen) .video-wrapper {
    margin-bottom: 90px;
  }

  .articlePage .image-gallery-content:not(.fullscreen) .image-gallery-right-nav,
  .articlePage .image-gallery-content:not(.fullscreen) .image-gallery-left-nav {
    margin-top: -45px;
  }

  .articlePage .image-gallery-content:not(.fullscreen) .image-gallery-fullscreen-button {
    margin-bottom: 95px;
  }

  .articlePage .image-gallery-slide .image-gallery-description {
    height: 95px;
  }
}