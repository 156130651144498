.open {
    display: block;
}

.close {
    display: none;
}

.backgroundsWrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
}

.backgroundsWrapper.centered {
    align-items: center;
}

.windowBackground {
    position: absolute;
    background-color: black;
    color: #ffffff;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0.6;
    z-index: 1;
}

.backgroundsWrapper:not(.centered) .modalBackground {
    right: 0%;
    top: 0%;
    bottom: 0%;
    width: 650px;
    display: flex;
    flex-direction: column;
}

.backgroundsWrapper.centered .modalBackground {
    border-radius: 5px;
    width: 550px;
}

.modalBackground {
    background-color: #ffffff;
    position: absolute;
    z-index: 2;
    max-height: 100vh;
    overflow: auto;
}

/** MODAL HEADER STYLING **/
.modalHeaderContainer {
    display: flex;
    align-items: center;
    background-color: #EFEFEF;
    height: 100px;
}

.modalHeader {
    display: flex;
    align-items: center;
    margin-left: 60px;
}

.modalHeader img,
.modalHeader svg {
    height: 18px;
    margin-right: 12px;
}

.modalHeader svg {
    width: 18px;
}

.modalHeader svg path {
    fill: #06778C;
}

.modalTitle {
    font-size: 20px;
    font-weight: 500;
    color: #06778C;
    margin: 0;
}

.modalSelectHeader {
    margin-top: 34px;
}

/** MODAL BODY STYLING **/
.modalBodyContainer {
    background-color: white;
    margin-left: 45px;
    margin-right: 45px;
    flex-grow: 1;
}

.modalBodyContainer .btnContainer {
    margin-bottom: 42px;
}

.modalSuccess {
    margin-top: 1em;
    margin-bottom: 1em;
}

.attributesWrapper {
    margin-top: 20px;
    display: flex;
}

.attributeContainer,
.attributeContainer > * {
    width: 100%;
}

.attributeContainer {
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.attributeContainer1 {
    padding-left: 15px;
    padding-right: 15px;
}

.btnContainer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.centeredButtons {
    padding-top: 20px;
    display: flex;
    justify-content: center;
}

.btnOptions {
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
}

.btnOptions > * {
    width: 100%;
}

.btnContainer.tableButtons {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}

@media (max-width: 1280px) {
    .backgroundsWrapper:not(.centered) .modalBackground {
        width: 550px;
    }
}