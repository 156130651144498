.errorWrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.errorWrapper .errorTitle {
    color: #1F8598;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.2;
}

.errorWrapper .errorDescription {
    margin-top: 10px;
    font-size: 1.44375rem;
}

.errorWrapper .errorBtn {
    margin-top: 20px;
}