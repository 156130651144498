.secondaryFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 12px;
  align-items: center;
}

.secondaryFooter > *:not(:last-child) {
  margin-right: 64px;
}

.loginPage .secondaryFooter {
  flex-direction: row;
  justify-content: center;
}

.loginPage .secondaryFooter > *:not(:last-child) {
  margin-right: 20px;
}

.footerTxt {
  color: #fcfcfc;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.8;
  text-align: left;
}

.footerImg {
  margin-top: 10px;
}

.footerImg > *:not(:last-child) {
  margin-right: 24px;
}

@media (max-width: 1280px) {
  .loginPage .secondaryFooter {
    flex-direction: column;
    justify-content: center;
  }
  .loginPage .secondaryFooter > *:not(:last-child) {
    margin-right: 51px;
  }
}

@media (max-width: 768px) {
  .secondaryFooter {
    flex-direction: column;
    justify-content: center;
  }
  
  .secondaryFooter > *:not(:last-child) {
    margin-right: 51px;
  }
}
