.finishContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.successTitle {
    text-align: center;
    color: #1F8598;
    line-height: 58px;
    font-size: 50px;
    font-weight: 700;
}

.successDescription {
    text-align: center;
    color: #767676;
    line-height: 25px;
    font-size: 20px;
    font-weight: 600;
}

.finishOptions {
    display: flex;
    margin-top: 48px;
}

.finishOptions > * {
    margin-right: 24px;
    margin-left: 24px;
}