.dashboardBody {
  margin-left: 300px;
}

.sideMenu {
  background-color: #e5fbff;
  color: #ffffff;
  min-height: 100vh;
  max-height: 100vh;
  width: 300px;
  position: fixed;
  top: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.5s ease-in-out;
}

.sideMenuCollapsed {
  background-color: #e5fbff;
  color: #ffffff;
  min-height: 100vh;
  max-height: 100vh;
  width: 54px;
  position: fixed;
  top: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: width 0.5s ease-in-out;
}

.logoContainer {
  display: flex;
  justify-content: space-between;
  margin-left: 8px;
  padding-right: 1rem;
}
.sideMenuCollapsed .logoContainer {
  margin-left: 0;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  padding: 0;
}

.menuOption,
.subMenuOption,
.menuLinkJournal {
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  cursor: pointer;
}

.menuOption {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}

.sideMenuCollapsed .menuOption {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  padding-left: 20px;
}

.subMenuOption {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

.menuOption.active,
.sideMenuCollapsed .menuOption.active {
  background-color: rgba(255, 255, 255, 0.6);
  border-left: 4px solid #1f8598;
}

.menuOption.active .linkWrapper,
.sideMenuCollapsed .menuOption.active .linkWrapper {
  left: -4px;
}

.menuOption {
  padding-left: 40px;
}

.subMenuOption {
  padding-left: 40px;
  padding-right: 40px;
}

.subMenuTitle {
  width: 100%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 30px;
}

.subMenuOption.active .subMenuTitle {
  background-color: rgba(255, 255, 255, 0.6);
}
.sideMenuCollapsed .menuTxt,
.sideMenuCollapsed .subMenuTxt,
.sideMenuCollapsed .menuTxt:hover,
.sideMenuCollapsed .subMenuTxt:hover,
.menuTxt,
.subMenuTxt,
.menuTxt:hover,
.subMenuTxt:hover {
  color: var(--color-true-blue);
}

.menuTxt,
.subMenuTxt {
  font-size: 16px;
}

.subMenuTxt {
  cursor: pointer;
}

.divLinkJournal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 2rem;
}

.menuLinkJournal {
  height: 38px;
  justify-content: center;
  color: white;
  background: var(--color-true-blue);
  border-radius: 4px;
  transition: 0.3s;
  font-size: 15px;
  min-width: 160px;
  max-width: 250px;
  font-weight: 400;
}

.sideMenuCollapsed .menuLinkJournal {
  height: 38px;
  justify-content: center;
  color: white;
  background: var(--color-true-blue);
  border-radius: 4px;
  transition: 0.3s;
  font-size: 16px;
  min-width: 40px;
  max-width: 250px;
}

.sideMenuCollapsed + .dashboardBody {
  margin-left: 54px;
  transition: margin-left 0.5s ease;
}

.sideMenuCollapsed .menuLinkJournal:hover,
.menuLinkJournal:hover {
  background: #065b6a;
  text-decoration: none;
  color: white;
}

.menuLinkJournal img {
  width: 22px;
  height: 22px;
  margin-right: 0.5rem;
}

.sideMenuCollapsed .menuLinkJournal img {
  margin: auto;
}

.sideMenuCollapsed .arrowButton {
  rotate: 180deg;
}

.subMenuArrow {
  margin-right: 1.5rem;
}

.linkWrapper {
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
}

.linkWrapper.menuTitle {
  cursor: pointer;
}

.linkIcon {
  display: flex;
  width: 30px;
}

.linkIcon img {
  width: 17px;
}
.arrowButton {
}

@media only screen and (max-width: 1650px) {
  .dashboardBody {
    margin-left: 280px;
    transition: margin-left 0.5s ease;
  }

  .sideMenu {
    width: 280px;
  }

  .menuTxt,
  .subMenuTxt {
    font-size: 15px;
    text-decoration: none;
  }
}

@media only screen and (max-width: 1280px) {
  .dashboardBody {
    margin-left: 260px;
  }

  .sideMenu {
    width: 260px;
  }

  .menuTxt,
  .subMenuTxt {
    font-size: 14px;
    text-decoration: none;
  }
}