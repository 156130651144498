.fileSquareContainer {
    position: relative;
    border-width: 1px;
    border-radius: 4px;
    border-color: #767676;
    border-style: dashed;
    background-color: #EFEFEF;
}

.fileSquareContainer::before {
    content: "";
    display: block;
    padding-top: 100%;
}

.fileDropzone {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    font-size: 15px;
    color: #767676;
    line-height: 125%;
    outline: none;
    text-align: center;
    cursor: pointer;
}

.uploadIconContainer {
    margin-top: 20px;
    margin-bottom: 20px;
}

.uploadIconContainer svg {
    transform: scale(1.3);
}

.uploadDescription {
    font-weight: 400;
    max-width: 220px;
}

.txtHighlight {
    color: #1F8598;
    text-decoration: underline;
    cursor: pointer;
}

.thumbContainer {
    max-width: 100%;
    max-height: 100%;
}

.thumbContainer img {
    display: block;
    width: 100%;
    height: 100%;
}