.colorOptions {
  margin-top: 15px !important;
}

.colorOptionContainer {
  display: flex;
  flex-direction: row;
}

.colorContainer {
  display: flex;
  background-color: #F4F4F4;
  height: 200px;
  border-radius: 4px;
  box-shadow: 3px 4px 6px 2px rgba(225, 225, 225, 0.75);
}

.colorContainer,
.paletteBtnContainer {
  width: 200px;
}

.paletteColor {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.02em;
  width: 50%;
}

.paletteColor.main {
  border-radius: 4px 0px 0px 0px;
}

.paletteColor.sec {
  border-radius: 0px 4px 0px 0px;
}

.paletteBottom {
  width: 100%;
  height: 25%;
  background-color: #F4F4F4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.paletteBottom p {
  margin-top: 0;
  margin-bottom: 0;
}

.paletteColor.main .paletteBottom {
  border-right: 1px solid white;
}

.paletteColor.sec .paletteBottom {
  border-left: 1px solid white;
}

.paletteTitle {
  font-weight: 700;
}

.paletteBtnContainer button {
  width: 100%;
}

.paletteSidebar {
  display: flex;
  flex-direction: column !important;
}

.selectedPalette {
  margin-bottom: 35px;
}

.selectedPalette .title {
  margin-top: 0;
  margin-bottom: 15px;
}

.selectedPalette .color {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.02em;
}

.selectedPalette .colorBox {
  width: 50px;
  height: 50px;
  margin-right: 5px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 1250px) {
  .colorContainer {
    height: 180px;
  }

  .colorContainer,
  .paletteBtnContainer {
    width: 180px;
  }
}

@media only screen and (max-width: 1100px) {
  .colorContainer {
    width: 160px;
    height: 160px;
  }

  .colorContainer,
  .paletteBtnContainer {
    width: 160px;
  }

  .paletteTitle {
    display: none;
  }

  .selectedPalette .pageHighlight {
    font-size: 18px;
  }
}

@media (max-width: 1024px) {
  .identityPreviz {
    display: none !important;
  }
}