.text-area-custom .MuiInputBase-root {
  height: auto !important;
}

.removeBtn {
 position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 2rem;
  right: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: rgba(255, 255, 255, 0.9);
  border: 0.1rem var(--color-true-blue) solid;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  padding: 0;
}

.removeBtn:hover,
.removeBtn:hover path{
  background-color: var(--color-true-blue);
  fill: white;
  transition: all .1s;
}

.imgPreviewContainer {
  position: relative;
}

.disclaimerTxtContainer {
  font-size: 13px;
  text-align: justify;
}