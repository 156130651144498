.unsupportedContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.unsupportedTxt {
    margin-top: 20px;
    padding-right: 15px;
    padding-left: 15px;
    font-weight: bold;
    font-size: 26px;
    color: #1F8598;
    text-align: center;
}

.unsupportedDescription {
    margin-top: 20px;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 18px;
    text-align: center;
    line-height: 25px;
}

@media only screen and (max-width: 780px) {
    .trueLogo {
        max-width: 250px;
        max-height: 175px;
    }

    .unsupportedContent .divisor {
        display: none;
    }
}

@media only screen and (max-width: 520px) {
    .unsupportedTxt {
        font-size: 22px;
    }

    .unsupportedDescription {
        font-size: 16px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 460px) {
    .trueLogo {
        max-width: 200px;
        max-height: 150px;
    }

    .unsupportedTxt {
        font-size: 20px;
    }

    .unsupportedDescription {
        font-size: 14px;
        line-height: 20px;
    }
}