@import '~react-image-gallery/styles/css/image-gallery.css';

.galleryContainer {
  position: relative;
}

.gallerySectionContainer .image-gallery-slide::after {
  content: '';
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 12%,
    rgba(0, 0, 0, 0.5) 25%,
    rgba(0, 0, 0, 0.7) 50%,
    rgba(0, 0, 0, 0.8) 75%,
    rgba(0, 0, 0, 1) 100%
  );
  opacity: 0.5;
  border-radius: 8px;
}

.sliderContent {
  width: 100%;
  position: absolute;
  bottom: 20px;
  padding-right: 65px;
  padding-left: 65px;
  text-align: center;
  z-index: 2;
}

.sliderContent .headlightTitle,
.sliderContent .newsItemSubtitle {
  color: #fcfcfc;
}

.image-gallery-bullets {
  top: -24px;
  bottom: unset;
}

.image-gallery-bullets .image-gallery-bullet {
  width: 12px;
  height: 12px;
  opacity: 0.2;
  border-radius: 8px;
  border: none !important;
  transform: unset !important;
  box-shadow: none;
}

.image-gallery-bullets .image-gallery-bullet.active {
  width: 30px;
  opacity: 1;
}

.image-gallery-bullets .image-gallery-bullet:hover,
.image-gallery-bullets .image-gallery-bullet:focus,
.image-gallery-bullets .image-gallery-bullet:active {
  opacity: 0.8;
}

.image-gallery-icon {
  padding: 0 !important;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  filter: unset;
}

.image-gallery-right-nav .image-gallery-svg,
.image-gallery-left-nav .image-gallery-svg {
  width: 12.5px;
  height: 22.5px;
  stroke-width: 2;
}

.image-gallery-left-nav {
  left: -60px;
}

.image-gallery-right-nav {
  right: -60px;
}

.image-gallery-content.fullscreen .image-gallery-left-nav {
  left: 5px;
}

.image-gallery-content.fullscreen .image-gallery-right-nav {
  right: 5px;
}

.image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 160px) !important;
  border-radius: 8px;
}

.image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
  max-height: 100vh !important;
}

.image-gallery-content.fullscreen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.image-gallery-content.fullscreen .image-gallery-slide-wrapper {
  flex-grow: 1;
}

.hpContainer .image-gallery-slide .image-gallery-description,
.image-gallery-content.fullscreen .image-gallery-slide .image-gallery-description,
.image-gallery-content.fullscreen .image-gallery-bullets {
  display: none;
}

.image-gallery-fullscreen-button {
  margin-bottom: 5px;
  margin-right: 5px;
}

.image-gallery-content.fullscreen .image-gallery-right-nav,
.image-gallery-content.fullscreen .image-gallery-left-nav,
.image-gallery-fullscreen-button {
  background-color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 1024px) {
  .sliderContent {
    padding-right: 45px;
    padding-left: 45px;
  }

  .image-gallery-icon {
    box-shadow: 2px 2px rgba(0,0,0,0.4);
  }

  .image-gallery-left-nav {
    left: 5px;
  }
  
  .image-gallery-right-nav {
    right: 5px;
  }

  .image-gallery-right-nav,
  .image-gallery-left-nav {
    background-color: rgba(255, 255, 255, 0.6) !important;
  }
}

@media (max-width: 420px) {
  .sliderContent .newsItemSubtitle {
    display: none;
  }
}