.homepageWrapper {
    margin-top: 35px;
    display: flex;
}

/* News selection rows styling */
.newsRowsContainer {
    flex: 1;
    margin-right: 100px;
}

.modalSectionsWrapper {
    padding-left: 30px;
}

.sectionsRowsContainer {
    position: relative;
    padding: 30px;
}

.newsRowsContainer .rowWrapper:not(:last-child),
.sectionsRowsContainer .rowWrapper:not(:last-child) {
    margin-bottom: 25px;
}

.rowWrapper {
    display: flex;
}

.blueSquare {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px transparent solid;
    background-color: #e5fbff;
    position: relative;
    border-radius: 5px 5px 5px 5px;
}

.squareNumber {
    color: var(--color-true-blue);
    font-size: 20px;    
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1em;
}

.greyRectangle {
    display: flex;
    justify-content: left;
    text-align: left;
    cursor: pointer;
    align-items: center;
    width: 100%;
    height: 70px;
    border: 2px transparent solid;
    background-color: #F4F4F4;
    margin-left: 18px;
    position: relative;
    border-radius: 5px 5px 5px 5px;
    max-width: 700px;
    font-weight: 500;
    padding: 0;
}

.greyRectangle.sections {
    flex: 1;
}

.greyRectangle.noPointer {
    cursor: default !important;
}

button.greyRectangle:hover{
    background-color: #f0f0f0;
}

.selectItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 17px;
}

.chooseItemModal {
    color: #707070;
    margin-top: 1em;
    margin-bottom: 1em;
}

.selectedItemTitle.sections {
    width: 100%;
    padding-left: 17px;
}

.selectedItemTitle.homepage {
    width: 90%;
    text-overflow: ellipsis;
    /* Needed to make it work */
    overflow: hidden;
    white-space: nowrap;
}

.selectedItemTitle {
    color: var(--color-true-blue);
    margin-top: 1em;
    margin-bottom: 1em;
}

.editIcon {
    position: absolute;
    height: 22px;
    right: 18px;
}

.arrowWrapper {
    width: 120px;
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.arrowWrapper img {
    cursor: pointer;
    height: 20px;
}

.arrowWrapper img:hover {
    opacity: 0.8;
}

.arrowWrapper img:first-child {
    margin-right: 30px;
}

.marginArrow {
    margin-left: 60px;
}

/* Select news modal styling */
.tableHeader {
    width: auto;
    margin-top: 34px;
    padding: 9px 24px;
    background: #E6FBFF;
    border-radius: 4px 4px 0px 0px;
}

.tableHeaderTitle {
    color: #1F8598;
    font-weight: 500;
    text-transform: uppercase;
}

.tableBodyContainer {
    margin-bottom: 34px;
}

.tableBodyTitle {
    color: #767676;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 10px;
    margin-bottom: 10px;
}

.tableHeaderTitle,
.tableBodyTitle {
    font-size: 15px;
}

.tableBody {
    margin-bottom: 1px;
    width: auto;
    padding: 1px 24px;
    border: 2px transparent solid;
    background-color: #F4F4F4;
    cursor: pointer;
}

.tableBodySelected {
    background-color: #1F8598;
}

.tableBodySelected .tableBodyTitle {
    color: white;
}

.tableBody:hover {
    filter: brightness(85%);
}

/* Right sidebar styling */
.rightSidebar {
    display: flex;
    flex-direction: column;
}

.schemeContainer {
    flex: 1;
}

.schemeBox {
    border: 2px transparent solid;
    background-color: #004552;
    position: relative;
    border-radius: 5px 5px 5px 5px;
}

.btnMargin {
    margin-top: 12px;
}

.hpIcon {
    height: 20px;
    width: 20px;
    margin-right: 5px;
    cursor: pointer;
}

.hpIcon path {
    fill: #004552;
}

.boxSquare, .boxSquare svg {
    width: 196px;
    box-sizing: unset;
}

.schemeTitle {
    color: white;
    text-align: center;
    font-size: 16px;
    margin-top: 6%;
}

@media only screen and (max-width: 1850px) {
    .arrowWrapper img {
        width: 25px;
    }

    .marginArrow {
        margin-left: 45px;
    }

    .arrowWrapper img:first-child {
        margin-right: 20px;
    }
}

@media only screen and (max-width: 1650px) {
    .newsRowsContainer {
        margin-right: 80px;
    }

    .boxSquare, .schemeBox svg {
        width: 165px;
        height: unset;
    }
}

@media only screen and (max-width: 1550px) {
    .greyRectangle:not(.sections) {
        max-width: 600px;
        flex: 1;
    }
}


@media only screen and (max-width: 1450px) {
    .newsRowsContainer {
        margin-right: 40px;
    }

    .greyRectangle:not(.sections) {
        max-width: 550px;
        flex: 1;
    }

    .blueSquare {
        width: 50px;
    }
}

@media only screen and (max-width: 1300px) {
    .greyRectangle:not(.sections) {
        max-width: 450px;
    }

    .blueSquare {
        width: 40px;
    }

    .selectedItemTitle {
        width: 85%;
    }

    .arrowWrapper {
        width: 100px;
    }
}

@media only screen and (max-width: 1150px) {
    .newsRowsContainer {
        margin-right: 20px;
    }

    .greyRectangle:not(.sections) {
        max-width: 350px;
        font-size: 15px;
    }
    
    .selectedItemTitle {
        width: 80%;
    }
}

@media only screen and (max-width: 1024px) {
    .greyRectangle:not(.sections) {
        max-width: 325px;
    }
}