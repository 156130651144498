.fullPageContainer {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.splashBackground {
    position: absolute;
    background-size: cover;
    background-position: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -2;
    border-radius: 8px;
}

.elementsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.elementsWrapper .buttonWrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}