:root {
  --font-family: 'Roboto', sans-serif;
  /* font-weight */
  --font-medium: 500;
  --font-regular: 400;
  --font-light: 300;
  /* font-size */
  --font-size-header: 23px;
  --font-size-title: 1.1rem;
  --font-size-text: 1rem;
  --font-size-small: 0.9rem;
  /* color */
  --color-white: #ffffff;
  --color-true-blue: #08798e;
  --color-light-blue: #cde2e6;
  --color-dark: #212529;
  --color-medium-grey: #aaaaaa;
  --color-dark-grey: #767676;
  --color-light-grey: #fcfcfc;
  /* shadow */

  /* a que estava
   --form-shadow: 2px 2px 3px rgba(0, 0, 0, 0.20); */

  /* teste
  --form-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15); 
  --form-shadow: 0px 2px 5px 1px rgba(60, 60, 60, 0.16); */

  --form-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  --hover-shadow: 2px 2px 5px 1px rgb(31 133 152 / 30%);
  --hover-shadow-button-secondary: 1px 1px 3px 0 rgb(31 133 152 / 30%);
  --hover-shadow-button-primary: 1px 1px 3px 0 rgb(31 133 152 / 80%);
}

/* CSS geral e do form notícia */

body {
  font-family: var(--font-family);
  background-color: var(--color-light-grey);
}

p {
  color: var(--color-dark);
}

a {
  text-decoration: none;
}

.header-help-style {
  padding: 1.5rem 2rem 0.2rem 2rem;
}

.font-header {
  font-weight: var(--font-medium);
  font-size: var(--font-size-header);
  color: var(--color-true-blue);
}

.font-title {
  font-weight: var(--font-regular);
  font-size: var(--font-size-title);
  color: var(--color-true-blue);
}

/* estilos do título notícia e do corpo da notícia */
.ql-container {
  border-radius: 5px;
  box-shadow: var(--form-shadow);
  border: 0 !important;
  background-color: var(--color-white);
}

#text-editor-body > .ql-container {
  border-radius: 0 0 5px 5px !important;
  min-height: 12.5rem;
}

#text-editor-title > .ql-container {
  border-radius: 0 0 5px 5px !important;
}

#text-editor-title > .ql-container:hover,
.ql-container:focus {
  box-shadow: var(--hover-shadow);
}

.ql-editor {
  font-family: var(--font-family);
  font-size: var(--font-size-text);
  font-weight: var(--font-light);
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.ql-toolbar {
  border-radius: 5px 5px 0px 0px;
  box-shadow: var(--form-shadow);
  border: 0 !important;
  background-color: var(--color-white) !important;
}

.close-icon {
  transform: rotate(45deg) !important;
}

.verif-danger > .ql-editor {
  border: #ea1515 2px solid;
}

/* margens labels e inputs */

.label-bottom-margin {
  margin-bottom: 0.5rem;
}

.form-bottom-margin {
  margin-bottom: 1.5rem;
}

/* estilos dos inputs, selects e area de carregar imagem */

.dzu-inputLabelWithFiles {
  font-family: var(--font-family) !important;
  margin-right: 5%;
  margin-bottom: 2%;
  align-self: flex-end !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  font-size: var(--font-size-small);
  line-height: 1.5 !important;
  min-height: auto !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border-radius: 0.375rem !important;
  border: solid var(--color-true-blue) 1px !important;
  background-color: var(--color-white) !important;
  color: var(--color-true-blue) !important;
  font-weight: var(--font-medium) !important;
}

.dzu-inputLabelWithFiles div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dzu-inputLabelWithFiles:hover {
  background-color: var(--color-true-blue) !important;
  color: white !important;
  transition: all 0.2s;
}

.dzu-inputLabelWithFiles:hover svg path {
  fill: white !important;
  transition: all 0.2s;
}

.dzu-inputLabelWithFiles:focus {
  box-shadow: var(--hover-shadow-button-primary) !important;
}

.dzu-submitButton:hover {
  box-shadow: var(--hover-shadow-button-primary) !important;
}

.dzu-submitButton:focus {
  box-shadow: var(--hover-shadow-button-primary) !important;
}

.dzu-submitButton {
  font-family: var(--font-family) !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  margin-right: 5%;
  font-size: var(--font-size-small);
  line-height: 1.5 !important;
  min-height: auto !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border-radius: 0.375rem !important;
  font-weight: var(--font-medium) !important;
  color: var(--color-white);
  background-color: var(--color-true-blue) !important;
  height: 100% !important;
  border: none;
}

.dzu-dropzone {
  margin-bottom: 1rem !important;
  box-shadow: var(--form-shadow);
  border: 0 !important;
  border-radius: 5px;
  font-size: 1rem !important;
  color: #212529 !important;
  overflow: auto;
}

.cropper-view-box {
  outline: 1px solid #1f8598 !important;
  outline-color: rgba(31, 134, 152, 0.75) !important;
}

.cropper-point {
  background-color: #1f8598 !important;
}

.dzu-submitButtonContainer {
  text-align: right !important;
}

.form-control,
.form-select,
.dzu-dropzone {
  box-shadow: var(--form-shadow);
  border: 0 !important;
  overflow: hidden !important;
}

.dzu-dropzone {
  border-radius: 5px !important;
}

.input-group:hover,
.form-select:focus,
.form-select:hover,
.form-control:focus,
.form-control:hover {
  box-shadow: var(--hover-shadow);
}

/* estilos mensagens de erro */
.errorVerifContainer {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  align-items: center;
}

.errorVerifMessage {
  margin: 0;
  color: #c93d3d;
  font-size: var(--font-size-small);
}
.errorVerifIcon {
  height: 1.25rem !important;
  width: auto !important;
}

/* carregar imagens */

.swiper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.swiper-slide {
  margin-top: 1rem;
  text-align: center;
  font-size: 12px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-button-prev {
  color: #1f8598 !important;
}

.swiper-button-next {
  color: #1f8598 !important;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #1f8598 !important;
}

.img-container {
  position: relative;
}

.erase {
  /* fill: #1f8598 !important; */
  border: 0.1rem #1f8598 solid;
  border-radius: 100%;
}

.top-right-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: rgba(255, 255, 255, 0.9);
  border: 0.1rem var(--color-true-blue) solid;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  padding: 0;
}

.top-right-button:hover,
.top-right-button:hover path {
  background-color: var(--color-true-blue);
  fill: white;
  transition: all 0.1s;
}

.bottom-left-button {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 0.1rem var(--color-true-blue) solid;
  border-radius: 15px;
  color: inherit;
  padding: 4px 18px 4px 18px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.bottom-left-button:hover {
  background-color: var(--color-true-blue);
  transition: all 0.2s;
}

.bottom-left-button span {
  color: var(--color-true-blue);
  font-weight: var(--font-medium);
}

.bottom-left-button:hover span,
.bottom-left-button:hover path {
  color: white;
  fill: white;
  transition: all 0.2s;
}

.cropper-drag-box {
  border-radius: 5px;
}

.swiper-slide img {
  display: block;
  width: 500px;
  height: calc(500px / 1.667);
  object-fit: cover;
  border-radius: 5px !important;
}

.swiper-slide textarea {
  resize: none;
  font-size: var(--font-size-small);
  margin-bottom: 10px;
}

.dzu-inputLabel {
  color: var(--color-true-blue) !important;
  background-color: var(--color-white);
}

.imageFormats {
  color: var(--color-dark-grey);
  font-weight: var(--font-regular);
  font-size: var(--font-size-small);
  margin-bottom: 0;
  margin-top: 4px;
}

.input-height {
  height: 40px;
  border-radius: 5px !important;
}

.padding-form {
  padding-right: 2rem !important;
  padding-left: 0px !important;
  padding-bottom: 2.5rem !important;
  margin-top: 5rem !important;
}

.padding-row {
  padding: 2rem 2rem 0 2rem;
}

/* select */

.form-select {
  height: 40px;
}

select:invalid {
  color: var(--color-dark-grey);
}
option {
  color: var(--color-dark);
}

/* campo pills com notícias relacionadas */

.no-input:focus,
.no-input:hover {
  background-color: none;
  box-shadow: var(--form-shadow);
}

/* input descrição carrousel imagens */

textarea {
  margin-bottom: 2rem;
}

.related-news-height {
  min-height: 40px;
  height: auto;
}

.btn-primary {
  border: none !important;
}

/* buttons */
.btnCancelNews {
  color: var(--color-true-blue) !important;
  background-color: var(--color-light-blue) !important;
  border: none !important;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 0.547rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem !important;
}

.btnSubmitNews {
  color: var(--color-white) !important;
  background-color: var(--color-true-blue) !important;
  height: 100% !important;
  border: none !important;
  border-radius: 0.25rem !important;
}

.button-text {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btnSubmitNews:focus,
.btnSubmitNews:hover,
.btnCorrecao:hover {
  box-shadow: var(--hover-shadow-button-primary);
}

.btnCancelNews:focus,
.btnCancelNews:hover {
  box-shadow: var(--hover-shadow-button-secondary);
}

/* Botões Sugestões Tab */
.reloadIconAnimation {
  animation: rotation 1s 1 linear;
}

.reloadIcon {
  max-height: 70px !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
/* Erro */

.btnErro {
  color: var(--color-dark-grey);
  font-size: var(--font-size-small);
  background-color: rgba(234, 21, 21, 0.2) !important;
  padding: 0.5rem;
  pointer-events: none;
}

.erroRasurado {
  text-decoration: line-through #ea1515;
  text-decoration-thickness: 2px;
}

.btnCorrecao,
.btnCorrecao:active {
  width: 100%;
  /* height: 100%; */
  font-size: var(--font-size-small);
  padding: 0.5rem;
}

.btn_noSuggest {
  border: solid #1f8598 1px !important;
  background-color: white !important;
  color: #1f8598 !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  font-size: var(--font-size-small);
  text-align: center;
}

.btn_noSuggest:hover {
  filter: drop-shadow(0);
  box-shadow: 0px 0px 0px transparent !important;
}

/* Section Tab  */
.section-tab {
  margin-top: 2.5rem;
  box-shadow: var(--form-shadow);
  background-color: var(--color-white);
  /* border-left: 1px solid rgba(118, 118, 118, 0.2); */
  /* box-shadow: -7px 0 6px -4px rgb(118 118 118 / 25%); */
  color: var(--color-dark-grey);
}

.padding-section-tab {
  padding: 2.5rem 2.5rem 2.5rem 1.25rem !important;
}

.section-tab-padding {
  padding: 1.5rem 1.5rem 2.5rem 2rem;
  position: relative;
}

.section-tab-title {
  color: var(--color-dark-grey);
  /* padding: 2.5rem 2.5rem 0.5rem 2.5rem; */
}

.margin-title-section {
  margin-bottom: 0.4rem;
}

/* Mascote */

.mascote {
  margin-top: 2.5rem;
  max-height: 70px;
}

.mascoteErro {
  max-height: 70px;
  margin-top: 2.5rem;
}

.mascoteText {
  font-size: var(--font-size-small);
}

/* Tabs */
.tabsDefault,
.tabsMenuOpen {
  position: fixed;
  height: 100vh;
  margin-right: 2rem;
  margin-top: 3.25rem !important;
  right: 0;
  transition: all 0.5s ease-out;
}

.tabsDefault {
  width: 30vw !important;
}

.tabsMenuOpen {
  width: 26vw !important;
}

.titleTabs {
  color: var(--color-dark-grey) !important;
}

.titleTabs.active {
  color: var(--color-dark);
  border-bottom: 1px solid white;
  box-shadow: none !important;
}

.iconCorrections {
  content: url('../images/icon_corrections_grey.svg');
}

.titleTabs.active .iconCorrections {
  content: url('../images/icon_corrections.svg');
}

.iconSearch {
  content: url('../images/icon_search_grey.svg');
}

.titleTabs.active .iconSearch {
  content: url('../images/icon_search.svg');
}

.iconNewsPublico {
  content: url('../images/icon_newsPublico_grey.svg');
}

.titleTabs.active .iconNewsPublico {
  content: url('../images/icon_newsPublico.svg');
}

.titleTabs.active .tabsText {
  color: var(--color-dark);
}

.nav-link:hover {
  color: var(--color-dark);
}

.nav-link {
  box-shadow: 1px 0 4px rgba(60, 60, 60, 0.16);
}

.nav-item {
  background-color: transparent;
  border-radius: 30px 30px 0 0;
}

/* Tab Correções */

.classErrors {
  max-height: 380px !important;
  overflow-y: auto !important;
  overflow-x: hidden;
  /* position: relative; */
  /* padding-right: 2%; */
}

/* Works on Firefox */
.classErrors {
  scrollbar-width: thin;
  scrollbar-color: rgba(118, 118, 118, 0.2) white;
}

/* Works on Chrome, Edge, and Safari */

.classErrors::-webkit-scrollbar {
  width: 8px;
}

.classErrors::-webkit-scrollbar-track {
  background: transparent;
  /*box-shadow: 7px 0 6px -4px red !important;*/
  border-radius: 5px;
  border-bottom: 1px solid rgba(118, 118, 118, 0.2);
  border-right: 1px solid rgba(118, 118, 118, 0.2);
}

.classErrors::-webkit-scrollbar-thumb {
  background-color: rgba(118, 118, 118, 0.3);
  border-radius: 20px;
}

/* pills styling */
.pillsMain {
  margin-top: 1rem !important;
  margin-bottom: 2.5rem;
}

.pillsHeaderText {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-dark-grey);
}

.pillsContainer {
  display: flex;
  flex-wrap: wrap;
  max-height: 90px !important;
  overflow-y: auto !important;
  overflow-x: hidden;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
}

.pillsDivActive,
.pillsDiv {
  border: 1px solid #418396;
  border-radius: 20px;
  padding: 1px 10px;
  margin: 2px;
  cursor: pointer;
  user-select: none;
  margin-right: 5px;
  margin-bottom: 5px;
  max-width: 120px;
}

.pillsDiv {
  background-color: #418396;
}

.pillsDivActive {
  background-color: white;
}

.pillsDiv:hover,
.pillsDivActive:hover {
  box-shadow: var(--hover-shadow);
}

.pillsContent,
.pillsActiveContent {
  color: white;
  font-size: 14px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pillsActiveContent {
  color: #418396;
}

/* Works on Firefox */
.pillsErrors {
  scrollbar-width: thin;
  scrollbar-color: rgba(118, 118, 118, 0.2) white;
}

/* Works on Chrome, Edge, and Safari */
.pillsErrors::-webkit-scrollbar {
  width: 8px;
}

.pillsErrors::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
  border-bottom: 1px solid rgba(118, 118, 118, 0.2);
  border-right: 1px solid rgba(118, 118, 118, 0.2);
}

.pillsErrors::-webkit-scrollbar-thumb {
  background-color: rgba(118, 118, 118, 0.3);
  border-radius: 20px;
}

/* Tab Pesquisa */

.progress {
  height: 0.5rem !important;
}

.progress-bar {
  background-color: var(--color-true-blue) !important;
}

/*Css Sugestões de correção e notícias relacionadas*/

.btn-closing {
  background: url('../images/icon_plus_grey.svg') !important;
  background-repeat: no-repeat !important;
  width: 22px;
  height: 22px;
  border: 0;
  transform: rotate(45deg);
  padding: 2px !important;
}

.btn-closing-pill {
  background: url('../images/icon_plus_white.svg') !important;
  background-repeat: no-repeat !important;
  width: 22px;
  height: 22px;
  border: 0;
  transform: rotate(45deg);
  padding: 2px !important;
}

.close-related {
  transform: rotate(45deg) scale(0.7) !important;
}

.close-modal {
  transform: rotate(45deg) scale(0.9) !important;
}

.testeClass {
  height: 260px;
}

/*Noticias relacionadas*/
.classRelated {
  max-height: 360px !important;
  overflow-y: auto !important;
  overflow-x: hidden;
}

.titleNews {
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--color-dark);
}

.titleNews:hover {
  color: var(--color-true-blue);
}

.titleNews:visited {
  color: var(--color-dark-grey);
}

.titleNews:visited:hover {
  color: var(--color-true-blue);
}

.fontNews {
  font-size: 0.75rem;
  font-weight: 400;
  color: var(--color-dark-grey);
}
/* tooltips */
.tooltip > .tooltip-inner {
  max-width: 18rem;
  padding: 0.5rem;
  text-align: left;
}

/*Btn Help*/

.helpPosition {
  top: 91vh;
  left: 92vw;
  z-index: 100;
  border-radius: 100;
}

#ajuda {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 160% !important;
  /* height: calc(100% - 70px); */
  height: inherit;
  z-index: 10000;
  position: absolute;
  left: 0%;
  margin: 0px;
}

.btnHelp {
  background-color: transparent !important;
  border: none !important;
  z-index: 1000 !important;
}

.btnHelp:focus {
  box-shadow: none !important;
}

#closeHelp {
  display: block;
}

#openHelp {
  display: block;
}

#openHelp:focus-visible {
  outline: 2px solid black;
  border-radius: 10rem;
}

.helpBallons {
  background-color: white;
  border-radius: 5px;
  width: 330px;
  padding: 10px;
  filter: drop-shadow(2px 2px 0px #1f8598);
  position: relative;
  top: 2%;
  left: 45%;
  /* font-family: 'Karla', sans-serif; */
  font-weight: 300;
  font-size: 1rem;
}

.rowHelpErrors {
  margin-top: 5px !important;
}

.rowHelpNews {
  margin-top: 173px !important;
}

#ajuda .col-8 {
  padding-right: 0px;
  position: relative;
  /*left:16px;*/
}

.spanArrow {
  position: fixed;
  width: 25px !important;
  height: 25px !important;
  left: 315px;
  top: 33px;
  background: #ffffff;
  border-radius: 2px;
  transform: rotate(135deg);
}

.spanArrow2 {
  top: 45px !important;
}

.modal-content {
  border-radius: 1rem !important;
  /* font-family: Karla !important; */
  font-weight: var(--font-light);
  max-height: 100% !important;
}

.textModalMargin {
  margin: 2rem 5rem;
}

.modal-lg,
.modal-xl {
  max-width: 1100px !important;
}

.modal-footer {
  border: none !important;
  justify-content: center !important;
}

#modal-news-result.modal-dialog {
  max-width: 650px;
}

#modal-news-result .modal-footer {
  padding-top: 0;
  padding-bottom: 1.5rem;
}

.imageInsideModal {
  /* height: 12rem; */
  /* height: 15rem; */
  max-height: 28vh;
}

.gifInsideModal {
  max-height: 38vh;
}

.modal-title {
  font-size: var(--font-size-title);
  /* color: var(--color-dark-grey) */
  /* padding-left: 1rem; */
}

.modalConfirmState {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

/***** Título ********/

.editable_Titulo {
  height: 50px;
  padding-top: 10px;
  padding-left: 10px;
  overflow: auto;
}

/******* Corpo de notícia****/

.editable_corpoNews {
  height: 250px;
  padding-top: 10px;
  padding-left: 10px;
  overflow: auto;
}

/********* Sinónimos *******/

.btn_sinonimos {
  background-color: transparent;
  border: none;
  color: black;
  margin: 0px;
  padding: 0px;
}

#Div1_tooltip,
#Div1_tooltip_editor {
  position: absolute;
  padding: 10px;
  background-color: white;
  color: #767676;
  filter: drop-shadow(0px 4px 3px rgba(60, 60, 60, 0.16))
    drop-shadow(2px 4px 4px rgba(60, 60, 60, 0.16));
  width: 12%;
  border-radius: 0.25rem;
  z-index: 10;
}

#p_tooltip {
  color: var(--color-dark-grey);
  float: left;
  width: 70%;
}

#p_modalCalendar {
  color: var(--color-dark-grey) !important;
}

#fecharModal {
  background-color: transparent;
  border: none;
  padding-left: 12px;
  color: #7c7c7c;
}

.cartas_palavras {
  border: solid var(--color-true-blue) 1px;
  /* background-color: rgba(31, 133, 152, 0.25); */
  background-color: white;
  color: var(--color-true-blue);
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 30px;
  margin-top: 10px;
}

.cartas_palavras_empty {
  display: inline-block;
  color: var(--color-true-blue);
  box-sizing: border-box;
  border-radius: 5px;
  margin: 5px 3px 10px 5px;
}

.cartas_palavras:hover {
  background-color: var(--color-light-blue);
}

#synonym_link {
  cursor: pointer;
  text-decoration: none;
  color: var(--color-true-blue);
}

/* .div_1 {
  display: inline-block;
}

.div_1 p {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
} */

/*Carrossel UploadImagensNoticia*/
.imgUpload {
  padding-top: 60px;
  padding-bottom: 30px;
}

.hideCarrossel {
  display: none;
}

.showCarrossel {
  display: block;
}

.thumb {
  height: 50px;
}

.carrosselItem {
  height: 175px;
  width: auto !important;
}

.thumb .carrosselItem {
  height: 45px !important;
  margin: auto;
}

.carousel .control-dots,
.carousel .carousel-status {
  display: none;
}

.btnRemoveImgPreview {
  position: absolute;
  top: 0px;
  right: 20px;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #1f8598 !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #1f8598 !important;
}

/**** Botões de guardar e cancelar ***/

#btn_set {
  margin-top: 60px;
}

/*separador noticias relacionadas*/
.conceptsBox {
  height: 75px;
}

.btnConcepts {
  border: 1px solid #1f8598;
  border-radius: 5px;
  padding: 5px;
  background-color: #1f85984d;
  margin: 2px;
  font-size: 0.75rem;
}

.btnConceptsGrey {
  border: 1px solid #767676;
  border-radius: 5px;
  padding: 5px;
  background-color: #7676764d;
  margin: 2px;
  font-size: 0.75rem;
}

#pal_sug_no_Aparece {
  padding-top: 6px;
}

.relatedNews {
  text-decoration: none !important;
}

.giveMeEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-height: 1.2rem;
  /* fallback */
  max-height: 3rem;
  /* fallback */
}

.descriptionNews {
  font-size: 14px;
  font-weight: 400;
}

.minImageNews {
  max-height: 4.5rem;
}

.divResults {
  /* position: relative; */
  margin: 2rem 0.5rem 2 0.5rem;
  max-height: 17rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* Works on Firefox */
.divResults {
  scrollbar-width: thin;
  scrollbar-color: rgba(118, 118, 118, 0.2) white;
}

/* Works on Chrome, Edge, and Safari */
.divResults::-webkit-scrollbar {
  width: 8px;
}

.divResults::-webkit-scrollbar-track {
  background: transparent;
  /*box-shadow: 7px 0 6px -4px red !important;*/
  border-radius: 5px;
  border-bottom: 1px solid rgba(118, 118, 118, 0.2);
  border-right: 1px solid rgba(118, 118, 118, 0.2);
}

.divResults::-webkit-scrollbar-thumb {
  background-color: rgba(118, 118, 118, 0.3);
  border-radius: 20px;
}

.btnAddNews {
  background-color: transparent !important;
  border: none !important;
}

.btnAddNews:active,
.btnAddNews:focus,
.btnAddNews:focus-visible {
  box-shadow: none !important;
}

.newsSearchIcon {
  height: 20px;
}

.inputSearch:focus {
  z-index: 0 !important;
}

.btnSearch {
  background-color: white !important;
  border: none !important;
  box-shadow: var(--form-shadow);
}

.btnSearch:focus,
.btnSearch:hover {
  box-shadow: 2px 2px 3px rgb(31 133 152 / 50%) !important;
}

.btnFilter {
  position: relative;
  background-color: white !important;
  border: none !important;
}

.btnFilter:focus,
.btnFilter:focus-visible {
  border: none !important;
  box-shadow: none !important;
}

.calendarSearch {
  vertical-align: sub !important;
  padding-bottom: 0px !important;
}

#divFilterCalendar {
  position: absolute;
  z-index: 1;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
  filter: drop-shadow(0px 4px 3px rgba(60, 60, 60, 0.16))
    drop-shadow(2px 4px 4px rgba(60, 60, 60, 0.16));
  border-radius: 0.25rem;
  width: 70%;
}

/* .position-search {
  top: 18%;
  left: 4%;
  right: 2%;
} */

.position-related {
  margin-left: 20.5%;
  margin-top: 3%;
}

#fecharModal {
  position: absolute;
  right: 20px;
}

#fecharModalBody {
  background-color: transparent;
  border: none;
  font-size: 0.8rem;
  /* padding-left: 12px; */
  color: #7c7c7c;
  top: 12px;
  position: absolute;
  right: 10px;
}

.opcoesCalendario {
  display: inline-block !important;
  border: solid #1f8598 1px !important;
  /*background-color: rgba(31, 133, 152, 0.25);*/
  background-color: white !important;
  color: #1f8598 !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  /*width: 95%!important;*/
  /*height: 30px!important;*/
  /*margin: 5px 5px 10px 5px!important;*/
  padding-left: 6px !important;
  padding-right: 6px !important;
  text-align: left !important;
}

.opcoesCalendario:hover,
.opcoesCalendario:active,
.opcoesCalendario.active {
  background-color: rgba(31, 133, 152, 0.25) !important;
}

.opcoesCalendario .btn {
  /*font-size:14px!important;*/
  background-color: transparent !important;
  color: #1f8598 !important;
  border: none !important;
  width: inherit !important;
  text-align: inherit;
}

#inputRelatedNews {
  margin-top: 50px;
}

.link-pill:hover,
.link-style:visited {
  color: var(--color-light-blue);
}

.pill {
  background-color: #1f8598 !important;
  border-radius: 0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.4rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin-top: 0.15rem;
  margin-bottom: 0.15rem;
}

.related-news-title {
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  font-size: var(--font-size-small);
  color: var(--color-white);
  width: 224px;
}

.related-news-item {
  margin-right: 0.75rem;
  display: inline-flex;
}

.color-title {
  color: var(--color-dark-grey);
}

/* Toast message styling */

.toastStyle {
  color: var(--color-true-blue);
  background-color: #e5fbff;
  font-weight: 500;
}

.toastStyle .Toastify__toast-body {
  font-family: var(--font-family);
}

.toastStyle .Toastify__progress-bar-theme--light {
  background: var(--color-true-blue);
}

.pad-image {
  padding-left: 0.8rem;
}

.tamanho_imagem {
  width: 800px;
}
