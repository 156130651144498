/* Position and sizing of burger button */
.bm-burger-button {
  /* position: fixed; */
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

.bm-burger-button button {
  width: 100px !important;
  height: 100px !important;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  /* background: #373a47; */
  background: #FFFFFF;
  width: 36px;
  height: 30px;
  margin-left: 36px;
  margin-top: 33px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  /* background: #a90000; */
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  /* background: #bdc3c7; */
  background: white;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  margin-top: -30px;
}

/* General sidebar styles */
.bm-menu {
  /* background: #373a47; */
  /* background: black; */
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  /* fill: #373a47; */
  /* fill: black; */
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}