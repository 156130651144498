.homepageHeader {
  background-color: #fcfcfc;
  padding: 24px;
  border-radius: 8px;
  margin: 0 auto;
}

.frontendNewsItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.newsItemImgLink {
  display: flex;
  width: 100%;
}

.newsImgContainer {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 56.25%;
  position: relative;
}

.newsImgContainerMobile{
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 56.25%;
  /* margin-bottom: 25px; */
  position: relative;
  transform: scale(1.2);
  transform-origin: center center;
}

.newsItemImg {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.newsItemImg:hover {
  opacity: 0.8;
}

.headlightTitle {
  margin-top: 16px;
  font-weight: 700;
  line-height: 120%;
  color: #07090a;
  text-decoration: none;
}

.headlightItemRow .headlightTitle {
  margin-top: 0;
}

.slotItemTitle.l3,
.slotItemTitle.l4 {
  margin-top: 16px;
}

.newsItemSubtitle {
  margin: 0;
  font-size: 14px;
  line-height: 135%;
  color: #07090a;
}

.newsItemSubtitle.homepage {
  margin-top: 8px;
}

.newsItemSubtitle.section {
  margin-top: 4px;
  margin-bottom: 10px;
}

.headlightTitle.l1.small,
.headlightTitle.l3.small,
.headlightTitle.l4.small,
.slotItemTitle.l3,
.slotItemTitle.l4 {
  font-size: 20px;
}

.headlightTitle.l1.big,
.headlightTitle.l4.big,
.slotItemTitle.l1,
.slotItemTitle.l2 {
  font-size: 28px;
}

.headlightTitle.l2.big {
  font-size: 36px;
}

.headlightTitle.l3.big {
  font-size: 32px;
}


@media (max-width: 1600px) {
  .headlightTitle.l1.small,
  .headlightTitle.l3.small,
  .headlightTitle.l4.small,
  .slotItemTitle.l3,
  .slotItemTitle.l4 {
    font-size: 18px;
  }

  .headlightTitle.l1.big,
  .headlightTitle.l4.big,
  .slotItemTitle.l1,
  .slotItemTitle.l2 {
    font-size: 24px;
  }

  .headlightTitle.l2.big {
    font-size: 32px;
  }

  .headlightTitle.l3.big {
    font-size: 28px;
  }
}

@media (max-width: 1366px) {
  .headlightTitle.l1.small,
  .headlightTitle.l3.small,
  .headlightTitle.l4.small,
  .slotItemTitle.l3,
  .slotItemTitle.l4 {
    font-size: 16px;
  }

  .headlightTitle.l1.big,
  .headlightTitle.l4.big,
  .slotItemTitle.l1,
  .slotItemTitle.l2 {
    font-size: 21px;
  }

  .headlightTitle.l2.big {
    font-size: 27px;
  }

  .headlightTitle.l3.big {
    font-size: 24px;
  }
}

@media (max-width: 1023px) {
  .slotItemTitle.l1,
  .slotItemTitle.l2 {
    font-size: 16px;
  }

  .headlightTitle.l2.big .headlightTitle.l3.big {
    font-size: 16px;
  }

  .headlightTitle.l5.small{
    font-size: 16px;
  }

  .homepageHeader {
    padding: 0;
    padding-top: 24px;
  }

  .newsImgContainerMobile{
    margin-bottom: 20px;
  }

  .frontendNewsItem{
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 900px){
  .newsImgContainerMobile{
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .frontendNewsItem{
    margin-bottom: 60px;
  }
}
