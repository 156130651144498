button,
input,
select {
  font-family: 'Roboto', sans-serif;
}

.signOutContainer {
  display: flex;
  align-items: center;
  background: none;
  border: none;
}

.signOutContainer img {
  width: 17px;
  margin-right: 8px;
}

.signOut {
  color: #767676;
  cursor: pointer;
  margin-top: 1em;
  margin-bottom: 1em;
}

.dashHeader {
  width: 93%;
  margin-right: 50px;

}


.bodyContainer {
  padding-bottom: 50px;
  width: 95%;
  margin: 0 auto;
}

.reportContainer {
  margin: 0 auto 0 0;
}

.imgPreviewReport {
  max-width: 400px;
}

/* Page highlight title */
.highlightContainer {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pageSectionContainer.head {
  margin-top: 35px;
}

.highlightContainer:not(.smallMargin) {
  margin-bottom: 35px;
}

.highlightContainer.smallMargin {
  margin-bottom: 15px;
}

.pageHighlight {
  color: var(--color-true-blue);
  font-size: 20px;
  font-weight: 500;
}

.pageHighlight.sections {
  padding-left: 30px;
}

.highlightDescription {
  color: #767676;
}

.optionsContainer {
  display: flex;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 35px;
}

.optionsContainerModal {
  display: flex;
  align-items: right;
  margin-top: 35px;
  margin-bottom: 35px;
}

/* Filter styling */
.filtersContainer,
.filterOptions {
  display: flex;
  align-items: center;
}

.filterOptions {
  margin-right: 64px;
}

.filterOptions > * {
  margin-left: 18px;
}

.filterContainerTitle {
  color: #1f8598;
  font-weight: 500;
  margin-top: 1em;
  margin-bottom: 1em;
}

.filterBox {
  width: 100%;
  display: flex;
  align-items: center;
}

.filterTitle {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 18px;
  color: #707070;
  flex: 1;
}

.hideFilter {
  display: none;
}

.filterArrow {
  height: 10px;
  margin-right: 18px;
}

.setFilterWrapper,
.setFilterWrapperDate {
  display: flex;
  align-items: center;
  position: relative;
  width: 190px;
  height: 34px;
  border: 2px transparent solid;
  background-color: #f4f4f4;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
}

.setOptionsWrapper,
.setOptionsWrapperDate {
  position: absolute;
  top: 34px;
  right: -2px;
  width: 190px;
  border: 2px transparent solid;
  background-color: #f4f4f4;
  border-radius: 0px 0px 4px 4px;
  padding-top: 5px;
  padding-bottom: 10px;
  z-index: 1;
}

.setOptionsWrapperDate {
  /* width: 475px; */
  /* width: 280px; */
  width: 300px;
  height: 380px;
}

.setOptions {
  margin-left: 20px;
  margin-right: 20px;
}

.filterLabel {
  margin-left: 5px;
  color: #767676;
}

.setOptionsCalendar {
  margin-left: 6%;
  display: block;
}

.setOptionsCalendar > * {
  margin-right: 20px;
}

.filterButtons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.filterButtonsC {
  display: flex;
  justify-content: center;
}

.cleanFilterButton,
.submitFilterButton {
  cursor: pointer;
  border-radius: 4px;
  text-align: justify;
  padding: 4px 12px;
  transition: 0.3s;
  display: block;
  text-decoration: none;
  background-color: #f4f4f4;
  border: #f4f4f4;
}

.cleanFilterButton {
  color: black;
  font-weight: bold;
}

.submitFilterButton {
  color: #1f8598;
}

/* Search styling */
.searchFilterWrapper {
  display: flex;
  align-items: center;
  height: 50px;
  flex-grow: 1;
}

.searchFilter {
  border: none;
  border-radius: 10px;
  resize: none;
  color: #a4a4a4;
  background-color: transparent;
  outline: none;
  flex: 1;
  min-width: 400px;
}

.searchFilter.small {
  min-width: 240px;
}

.searchFilterWrapper img {
  margin-right: 8px;
}

/* Main button styling */
.btnWrapper {
  cursor: pointer;
}

.configLink {
  text-decoration: none;
}

.mainBtn,
.secondaryBtn,
.secondaryBtnBlue,
.whiteBtn {
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  text-align: justify;
  /*padding-left: 18px;
    padding-right: 18px;*/
  transition: 0.3s;
  text-decoration: none;
  border: none;
  min-width: 200px;
  max-width: 250px;
  box-shadow: 3px 4px 6px 2px rgba(225, 225, 225, 0.75);
}

.signOut,
.highlightDescription,
.filterContainerTitle,
.searchFilter,
.mainBtn,
.secondaryBtn,
.secondaryBtnBlue,
.whiteBtn {
  font-size: 16px;
}

.filterTitle,
.cleanFilterButton,
.submitFilterButton {
  font-size: 15px;
}

.mainBtn {
  background-color: var(--color-true-blue);
  color: white;
}

.mainBtn:hover {
  text-decoration: none;
  color: white;
}

.mainBtn:hover {
  background-color: #065b6a;
}

.mainBtn:disabled {
  background-color: #aaaaaa;
  cursor: default;
}

.mainBtn img,
.mainBtn svg {
  width: 18px;
  margin-right: 8px;
}

.mainBtn svg {
  height: 18px;
}

.secondaryBtn {
  color: white;
  background-color: #767676;
}

.secondaryBtn:hover {
  background-color: #454545;
}

.secondaryBtnBlue {
  color: var(--color-true-blue);
  background-color: #e6fbff;
}

.secondaryBtnBlue.goBack {
  color: #767676;
}

.secondaryBtnBlue.goBack svg {
  margin-right: 10px;
}

.secondaryBtnBlue:hover {
  background-color: #dbf0f4;
}

.whiteBtn {
  color: #767676;
  background-color: #efefef;
}

.accessibleBtn {
  background: none;
  border: none;
}

/* Toast message styling */
.toastStyle {
  color: #1f8598;
  background-color: #e5fbff;
  font-weight: 500;
}

.toastStyleDanger {
  color: #8c1b1b;
  background-color: #ffe5e5;
  font-weight: 500;
}

.toastStyleSuccess {
  color: #375F30  ;
  background-color: #DEF2D6 ;
  font-weight: 500;
}

.toastStyleWarning {
  color: #544b12;
  background-color: #ffffe5;
  font-weight: 500;
}

.toastStyle .Toastify__toast-body {
  font-family: 'Karla', sans-serif;
}

.toastStyle .Toastify__progress-bar-theme--light {
  background: #1f8598;
}

.toastStyleDanger .Toastify__progress-bar-theme--light {
  background: #8c1b1b;
}

.toastStyleSuccess .Toastify__progress-bar-theme--light {
  background: #375F30 ;
}

.toastStyleWarning .Toastify__progress-bar-theme--light {
  background: #544b12;
}

/* Select styling */
.default-option-hidden {
  display: none !important;
}

.class-font-colour-gray {
  color: #767676 !important;
}

/** Profile pages styling **/
.profileInputContainer {
  padding-bottom: 8px;
}

.profileInputContainer > * {
  width: 100%;
}

.profileInputContainer.leftSide {
  padding-right: 16px;
}

.attributesWrapperProfile {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}

.goBackContainer {
  margin-bottom: 34px;
}

.modalProgressBar {
  width: 80%;
  justify-self: center;
  height: 0.5rem;
}

.modal-content {
  width: 60vw;
  margin: auto;
}

.btn-close {
  background: url('../newsEditor/images/icon_plus_grey.svg');
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(45deg);
}

@media only screen and (max-width: 1850px) {
  .optionsContainer.big {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1650px) {
  .dashHeader {
    width: 94%;
    margin-right: 20px;

  }

  .bodyContainer {
    width: 92%;
    margin: 0 auto;
  }

  .reportContainer {
    max-width: 860px;
    margin: 0 auto 0 0;
  }

  .optionsContainer:not(.sections) {
    flex-direction: column;
  }

  .mainBtn, .secondaryBtn,
  .secondaryBtnBlue {
    min-width: 165px;
  }

  .signOut,
  .highlightDescription,
  .filterContainerTitle,
  .searchFilter,
  .mainBtn,
  .secondaryBtn,
  .secondaryBtnBlue,
  .whiteBtn {
    font-size: 15px;
  }

  .filterTitle,
  .cleanFilterButton,
  .submitFilterButton {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1350px) {
  .dashHeader {
    width: 93%;
    margin-right: 20px;

  }

  .bodyContainer {
    width: 93%;
    margin: 0 auto;
  }

  .filtersContainer {
    flex-direction: column;
  }

  .filterOptions {
    margin-left: 0;
  }

  .setFilterWrapper,
  .setFilterWrapperDate,
  .setOptionsWrapper {
    width: 180px;
  }

  .setOptionsWrapperDate{
    width: 280px;
  }

  .btnWrapper {
    padding-top: 15px;
  }

 
}

@media only screen and (max-width: 1470px) {
  .tabsText {
    font-size: var(--font-size-small);
  }

  .titleTabs {
    padding-left:  0.25rem;
    padding-right:  0.25rem;
  }
}

@media only screen and (max-width: 1200px) {
  .tabsText {
    display: none;
  }
}

.custom-user-input {
  background-color: white !important;
  border: 1px solid #1f859869;
}
.custom-user-input-disabled {
  background-color: #efefef !important;
  /* border: 1px solid #1f859869; */
}
