.slotTitleContainer {
  width: 100%;
  height: 20px;
  text-align: center;
  margin-bottom: 44px;
}

.slotTitle {
  font-weight: 900;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 1px;
  color: #07090a;
  padding: 0 24px;
  background-color: #fcfcfc;
}

.slotSectionContainer {
  margin-top: 120px;
  width: 100%;
}

.slotSection {
  margin: 0 auto;
}

.slotItemTitle {
  font-weight: 700;
  line-height: 135%;
  color: #07090a;
  text-decoration: none;
}

.publicoSubtitle {
  color: #64676b;
  font-weight: 400;
  font-size: 18px;
  line-height: 135%;
  /* text-align: center; */
  margin: 0;
}

/* .publicoSubtitle + div {
  display: flex;
  flex-direction: column;
} */

.pDate {
  color: #d10019;
  margin-top: 2rem;
  margin-bottom: 4px;
  font-size: 15px;
  line-height: 135%;
  text-transform: uppercase;
}
.slotSection1 .pTitle {
  font-weight: bold;
  transition: color 0.3s ease;
  /* margin-bottom: 32px; */
  text-decoration: none;
  color: black;
  margin: 0;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.pTitle:hover {
  opacity: 0.8;
  text-shadow: 1px 1px rgba(255, 255, 255, 0.8);
  color: #d10019;
}

/* .pTitleContainer:hover {
  opacity: 0.8;
  text-shadow: 1px 1px rgba(255, 255, 255, 0.8);
  color: #d10019;
} */

.emptySlot {
  color: #07090a;
  text-align: center;
}


.slotSection1 {
  margin: 0 auto;
}
.pTitleContainer{
  height: 90px;
}
/*
.slotSection1 {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.slotSection1 .pTitleContainer {
  height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
*/

@media (max-width: 1023px) {
  .slotSectionContainer {
    margin-top: 50px;
  }
  .slotTitle {
    letter-spacing: 0px;
  }
  .slotSection1 .pTitle {
    font-weight: bold;
    transition: color 0.3s ease;
    /* margin-bottom: 32px; */
    text-decoration: none;
    color: black;
    white-space: normal;
  }
  .pTitleContainer{
    height: unset;
  }
}

@media (max-width: 653px) {
  .slotSection {
    padding-top: 30px;
  }
}
