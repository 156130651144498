.sectionHeader {
  background-color: #fcfcfc;
  padding: 24px;
  border-radius: 8px;
  margin: 0 auto;
}

.buttonSeeMore {
  border-radius: 8px;
  /*border: none;*/
  color: white;
  width: 100%;
  height: 40px;
  border: 2px solid transparent;
}

/* .buttonSeeMore:hover {
  background-color: rgba(26, 30, 34, 0.4) !important;
} */
