.calendarInput {
  width: 100px;
  height: 30px;
  border-radius: 0.3rem;
  /* background-color: white; */
  border: 1px solid #1f859869;
  text-align: center;
}

.react-datepicker {
  font-family: 'Roboto' !important;
  /* border: 1px solid #1f859869 !important; */
  border: none !important;
}

.react-datepicker__navigation {
  color: #767676;
  width: 45px;
  height: 45px;
}

.react-datepicker__month-container {
  /* background-color: #f4f4f4 !important; */
}

.react-datepicker__header {
  background-color: #f4f4f4 !important;
  /* border-bottom: 1px solid #1f859869 !important; */
  border-bottom: 0.1px solid #767676 !important;
  /* background-color: #e5fbff !important; */
}

.labelStyle {
  color: #1f8598;
  margin-right: 4px;
}

.styleInputs {
  display: flex;
  padding-bottom: 15px;
  justify-content: center;
  padding-top: 10px;
}

.optionsCell {
  min-width: 145px;
}

.newsNoTitle {
  font-style: italic;
}