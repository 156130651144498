.trueFooter {
  background-color: #1f8598;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1131' height='260' viewBox='0 0 1131 260' fill='none'%3E%3Cg clip-path='url(%23clip0_110_2)'%3E%3Cpath d='M1131 0H0V260H1131V0Z' fill='%231F8598'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1145.99 94.2883C1195.34 75.6969 1255.34 34.5629 1293.94 77.9026C1332.43 121.107 1304.67 201.369 1299.26 266.345C1294.71 320.861 1293.89 377.359 1265.54 418.61C1235.45 462.39 1191.45 483.599 1145.99 489.368C1094.66 495.882 1037.6 497.285 999.992 452.308C961.719 406.535 944.896 330.576 959.504 266.345C972.346 209.915 1028.24 198.398 1065.59 163.934C1092.88 138.758 1113.68 106.461 1145.99 94.2883Z' fill='%23005B6C'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M-10.943 491.95C-67.1474 504.613 -116.204 459.117 -167.803 428.103C-231.677 389.712 -317.223 377.302 -341.85 293.062C-367.496 205.332 -323.837 111.451 -283.187 33.4039C-243.628 -42.5489 -190.845 -107.994 -121.395 -130.207C-50.4017 -152.913 34.1891 -149.65 86.8034 -84.9343C135.566 -24.9568 108.168 75.6194 115.678 161.391C121.737 230.599 149.912 299.319 125.929 361.928C99.9984 429.622 47.3356 478.821 -10.943 491.95Z' fill='%23005B6C'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_110_2'%3E%3Crect width='1131' height='260' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center, 50%, 50%;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 750px;
  margin: 0px auto;
  padding: 50px 20px;
}

.footerBkg {
  width: 100%;
  height: auto;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .footerBkg {
    width: unset;
  }

  .loginPage .footerContainer {
    padding: 30px 20px;
  }
}
