.setupPage {
    margin-bottom: 50px;
}

.setupBody {
    width: 850px;
    margin: auto;
}

.setupBody.isLoading {
    position: relative;
    opacity: 0.5;
}

.loadingContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.setupBody.isLoading .loading {
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 2;
}

.setupTitle {
    font-weight: 500;
    color: #1F8598;
    font-size: 22px;
    margin-bottom: 0;
}

.setupSubtitle {
    color: #767676;
    font-weight: 500;
    font-size: 18px;
    margin-top: 8px;
}

.setupWrapper {
    display: flex;
    justify-content: center;
    background-color: #FCFCFC;
    padding-top: 36px;
    padding-bottom: 36px;
}

.setupOption {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
}

.setupOption .setupNumber {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: #AAAAAA;
    color: white;
    font-size: 20px;
}

.setupOption .setupDescription {
    margin-top: 12px;
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    color: #AAAAAA;
    line-height: 127.4%;
}

.setupOption.selected .setupNumber {
    background: #1F8598;
}

.setupOption.selected .setupDescription {
    color: #1F8598;
}

.setupOptWrapper {
    display: flex;
}

.setupSeparator {
    width: 110px;
    height: 0px;
    border: 1px solid #AAAAAA;
    margin-top: 17.5px;
    margin-left: -20px;
    margin-right: -20px;
}