.headlightItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headlightItem,
.headlightItem .secondaryContainer button {
    width: 100%;
}

.headlightItem svg {
    cursor: pointer;
    margin-bottom: 16px;
}

.headlightItem.selected .background {
    fill: #1F8598;
}

.headlightBtnContainer .secondaryBtn {
    width: 208px;
}