.mainFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 4px solid #005b6c;
  padding-bottom: 29px;
  margin-bottom: 24px;
}

.projectDescription {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
}

.descriptionTitle,
.descriptionSubtitle {
  color: #fcfcfc;
}

.descriptionTitle {
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 8px;
}

.secondaryBtnWhite {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fcfcfc;
  background-color: #1f8598;
  border: 1px solid #fcfcfc;
  border-radius: 100px;
  padding: 10px 25px;
  text-decoration: none;
  transition: 0.2s;
}

.secondaryBtnWhite:hover {
  background-color: #fcfcfc;
  color: #1f8598;
}

@media (max-width: 1280px) {
  .mainFooter {
    justify-content: start;
  }

  .mainFooter {
    padding-bottom: 10px;
  }
  
  .descriptionTitle {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .footerBtn {
    display: none;
  }
}
