@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700&family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

:root {
  --homepage-width: 60%;
  --section-width: 55%;
  --article-width: 50%;

  --homepage-width-xxl: 67%;
  --section-width-xxl: 62%;
  --article-width-xxl: 55%;

  --homepage-width-xl: 75%;
  --section-width-xl: 70%;
  --article-width-xl: 60%;

  --homepage-width-lg: 80%;
  --section-width-lg: 75%;
  --article-width-lg: 65%;

  --homepage-width-sm: 90%;
  --section-width-sm: 90%;
  --article-width-sm: 94%;

  --homepage-width-xs: 90%;
  --section-width-xs: 100%;
  --article-width-xs: 94%;
}

html,
body {
  font-family: 'Roboto', sans-serif !important;
  margin: 0;
  overflow-x: hidden;
}

.backgroundPage {
  min-height: 100%;
  width: 100%;
  background-color: #efefef29;
  position: absolute;
}

.emptyStateFill {
  height: 250px;
  margin-top: 15%;
}

.emptyStateFill.small {
  height: 150px;
}
